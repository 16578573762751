//import { Result, Row, Col } from "antd";
import Decoration from "../components/layout/decoration";
import Typography from "@material-ui/core/Typography";
const NotFound = () => {
  return (
    <div>
      <Decoration
        mode={'light'}
        gradient={true}
        decoration={true}
        bgPosition={'half'}
        horizontalMenu={false}
      />
      <div style={{display:'flex', justifyContent:'center', alignContent:'center',marginTop:'100px'}}>
        <Typography variant="h1" align="center">NOT FOUND<br></br> design will be here</Typography>
      </div>
    </div>

  );
};

export default NotFound;
