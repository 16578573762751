export default async function initialization(data) {
  var apiKey = data.token;
  const PayMe = window.PayMe;

  const cnc = document.getElementById('card-number-container-he');

  if (cnc) {
    cnc.innerHTML = ""
    document.getElementById('card-expiration-container-he').innerHTML = "";
    document.getElementById('card-cvv-container-he').innerHTML = "";
  }


  function tokenizationFinished(error) {
    console.log('Tokenization finished!');

    if (error) {
      console.error(error);
      var errorLabel = document.getElementById('global-error');
      if (error.type && error.type === 'tokenize-error') {
        errorLabel.innerText = error?.payload?.status_error_details;
      } else {
        errorLabel.innerText = error.message;
      }
    }
  }

  const allFieldsReady = [];

  const DEFAULT_SETTINGS = {
    styles: {
      base: {
        height: '32px',
        'font-size': '16px',
        ':focus': { 'color': 'green' },
        // '::placeholder': { 'color': '#ACD7E4' },
        'text-align': 'right'
      },
      invalid: {
        'color': 'red',
      },
      valid: {
        'color': 'green',
      },
    }
  };
  function toggleCardNumberValidationMessages(validationResult) {

    var errorLabel = document.getElementById("card-number-error");
    if (validationResult.isValid) {
      errorLabel.innerText = "";
    } else {
      errorLabel.innerText = validationResult.message;
    }
  }
  function toggleCardExpiryValidationMessages(validationResult) {

    var errorLabel = document.getElementById("card-expiration-error");
    if (validationResult.isValid) {
      errorLabel.innerText = "";
    } else {
      errorLabel.innerText = validationResult.message;
    }
  }
  function toggleCardCvvValidationMessages(validationResult) {

    var errorLabel = document.getElementById("card-cvv-error");
    if (validationResult.isValid) {
      errorLabel.innerText = "";
    } else {
      errorLabel.innerText = validationResult.message;
    }
  }
  async function init() {
    if (PayMe == null || PayMe == undefined) {
      window.location.reload()
      return
    }
    var i = await PayMe.create(apiKey, { testMode: false, language: 'he' }).then((instance) => {
      const fields = instance.hostedFields();

      const cardNumberSettings = Object.assign({}, DEFAULT_SETTINGS, {
        placeholder: '---- ---- ---- ----',
        messages: {
          invalid: 'מספר כרטיס אשראי לא תקין',
          required: 'שדה מספר כרטיס אשראי הינו חובה'
        },
      });
      const cardNumber = fields.create(PayMe.fields.NUMBER, cardNumberSettings);
      allFieldsReady.push(
        cardNumber.mount('#card-number-container-he')
      );
      // cardNumber.on('card-type-changed', ev => changeCardProviderIcon(ev.cardType));
      cardNumber.on('keyup', toggleCardNumberValidationMessages);
      // cardNumber.on('keyup', (e) => {
      //   if (e.isValid) {
      //     expiration.focus();
      //   }
      //   e.isEmpty ? removeClass('card-expiration-group-he', 'animate-card-option') : addClass('card-expiration-group-he', 'animate-card-option');
      //   e.isEmpty ? removeClass('card-cvv-group-he', 'animate-card-option') : addClass('card-cvv-group-he', 'animate-card-option');
      // });

      // Expiry Date
      const expirationField = Object.assign({}, DEFAULT_SETTINGS, {
        messages: {
          invalid: 'כרטיס פג תוקף',
          required: 'שדה תוקף הינו חובה'
        },
      });

      const expiration = fields.create(PayMe.fields.EXPIRATION, expirationField);
      allFieldsReady.push(
        expiration.mount('#card-expiration-container-he')
      );
      expiration.on('keyup', toggleCardExpiryValidationMessages);
      expiration.on('validity-changed', toggleCardExpiryValidationMessages);
      // expiration.on('keyup', (e) => {
      //   if (e.isValid) {
      //     cvc.focus();
      //   }
      // });

      // CVC/CVV
      const cvcField = Object.assign({}, DEFAULT_SETTINGS, {
        placeholder: '---',
        messages: {
          invalid: 'CVV שגוי',
          required: 'שדה CVV הינו חובה'
        },
      });
      const cvc = fields.create(PayMe.fields.CVC, cvcField);
      allFieldsReady.push(
        cvc.mount('#card-cvv-container-he')
      );
      cvc.on('keyup', toggleCardCvvValidationMessages);
      cvc.on('validity-changed', toggleCardCvvValidationMessages);

      console.log("ended in function")
      return instance;
      //  Promise.all(allFieldsReady).then(() => submitButton.disabled = false);

      // Form submission handler -----------------------------------------------


      // async function formSubmit(ev) {
      //   const sale = {
      //     payerFirstName: firstNameInput.value,
      //     payerLastName: lastNameInput.value,
      //     payerEmail: emailInput.value,
      //     payerPhone: phoneInput.value,
      //     payerSocialId: socialIdInput.value,

      //     total: {
      //       label: 'Gift',
      //       amount: {
      //         currency: 'ILS',
      //         value: totalAmountInput.value,
      //       }
      //     }
      //   };

      //   console.log('Tokenization started!');
      //   var errorLabel = document.getElementById('global-error');
      //   errorLabel.innerText = "";

      //   instance.tokenize(sale)
      //     .then(async (d) => {
      //       var res = await DataService.post(API.index.post, {
      //         token: d.token,
      //         amount: d.total.amount.value,
      //         hash: token,
      //         fee: feeInput.value
      //       })
      //       if (res.data.success) {
      //         setShowSuccess(true);
      //         tokenizationFinished();
      //       }
      //       else if (res.data.message && res.data.success == false) {
      //         tokenizationFinished({ message: res.data.message });
      //       }
      //     })
      //     .catch(err => {
      //       tokenizationFinished(err);
      //     });
      // };

    });
    return i;
  }
  window.PaymeAPI = await init();
}

