import React, { useState, useEffect } from "react";
import {
  Grid, TextField, Card, Button, CardContent, CardActions,
  Typography, Slide, Slider, FormControl, InputLabel, Dialog
} from "@material-ui/core";
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import NotFound from "./404";
import { API } from "../api/index";
import { DataService } from "../api/dataService";
import Decoration from "../components/layout/decoration";
import styles from "./styles-jss";
import PaymeAPI from '../api/payme'
import InputAdornment from "@material-ui/core/InputAdornment";
import * as yup from 'yup'
import { useFormik } from 'formik'
import LoadingButton from '../components/ui/loadingButton'
import InputMask from "react-input-mask";
import ReCAPTCHA from "react-google-recaptcha";
import { FEDTax, BitFee } from "../api/appSettings";
import Footer from '../components/layout/footer';
import { Hidden } from "@material-ui/core";
import Bugsnag from '@bugsnag/js'
const Pay = (props) => {
  const { classes } = props;
  let { token } = useParams();
  const [show404, setShow404] = useState(false);
  const [fees, setFees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0)
  const [fee, setFee] = useState(0);
  const [exited, setExited] = useState(true)
  const [installment, setInstallment] = useState(1);
  const [redirectURL, setRedirectURL] = useState('')
  const animation = 1000;
  const recaptchaRef = React.createRef();
  const validationSchema = yup.object({
    amount: yup
      .number()
      .typeError("גובה המתנה חייב להיות בין 5 ל-5,000 ש\"ח")
      .min(5, "גובה המתנה חייב להיות בין 5 ל-5,000 ש\"ח")
      .max(5000, "גובה המתנה חייב להיות בין 5 ל-5,000 ש\"ח")
      .required("שכחתם להזין את סכום המתנה :)"),

  });
  const formik = useFormik({
    initialValues: {
      amount: '',
      method: 'Credit Card'
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      var res = await DataService.get('GetCommissions?paymentAmount=' + values.amount)
      if (res.data.success) {
        setExited(false);
        setFees(res.data.data);
        let bitFee = 0;
        if (values.method == "Credit Card") {
          setCurrentStep(currentStep + 1);
        } else {
          setCurrentStep(4);
          bitFee = (BitFee * FEDTax) + (BitFee * FEDTax * BitFee / 100 * FEDTax);
          // bitFee = (BitFee * FEDTax) ;
        }

        if (res.data.data.length > 0) {
          setInstallment(res.data.data[0].numberOfPayments)
          if (res.data.data[0].percentage == false) {
            var bitPercentage = (parseFloat(formik.values.amount) + res.data.data[0].fees) * (bitFee / 100);
            setFee(round((res.data.data[0].fees + bitPercentage), 1))
          }
          else {
            // var bitPercentage = res.data.data[0].fees * (bitFee/100);
            var bitPercentage = (bitFee);
            // console.log("bitFee =>" + bitFee)
            // console.log((res.data.data[0].fees + bitPercentage))
            // console.log("res.data.data[0].fees * (bitFee/100) =>" + res.data.data[0].fees * (bitFee/100))
            // console.log((parseFloat(formik.values.amount) * (res.data.data[0].fees + bitPercentage)) / 100)
            var feeswithoutBitCalc = parseFloat(formik.values.amount) * res.data.data[0].fees / 100;
            //console.log("feeswithoutBit =>" + feeswithoutBitCalc)
            var feeBitCalc = (parseFloat(formik.values.amount) + feeswithoutBitCalc) * bitFee / 100;
            //console.log("feeBitCalc =>" + feeBitCalc)
            //console.log(feeswithoutBitCalc + feeBitCalc)
            setFee(round(feeswithoutBitCalc + feeBitCalc, 1))
          }
        }
      }
    }
  });
  function isValidIsraeliID(input) {
    var id = String(input).trim();
    if (id.length > 9 || id.length < 5) return false;

    // Pad string with zeros up to 9 digits
    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

    return Array
      .from(id, Number)
      .reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
      }) % 10 === 0;
  }
  const validationSchema1 = yup.object({
    firstName: yup.string().required('חובה להזין שם פרטי'),
    lastName: yup.string().required('חובה להזין שם משפחה'),
    // email: yup.string().email('אימייל שגוי').required('דואר אלקטרוני (חובה'),
    phone: yup.string().required('חובה להזין מספר טלפון'),
    socialId: yup.string().required('חובה להזין תעודת זהות')

  });
  const formik1 = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: 'wiwi@nuvola.co.il',
      phone: '',
      socialId: ''
    },
    validationSchema: validationSchema1,
    onSubmit: async (values, actions) => {

      if (values.phone.includes("_")) {
        formik1.setFieldError("phone", "טלפון לא חוקי")
        return;
      }
      if (!isValidIsraeliID(values.socialId)) {
        formik1.setFieldError("socialId", "תעודת זהות לא תקינה")
        return;
      }
      var amount = parseFloat(formik.values.amount);
      const sale = {
        payerFirstName: values.firstName,
        payerLastName: values.lastName,
        payerEmail: values.email,
        payerPhone: values.phone.length == 8 ? `05${values.phone}` : values.phone,
        payerSocialId: values.socialId,

        total: {
          label: 'Gift',
          amount: {
            currency: 'ILS',
            value: (amount + fee).toString(),
          }
        }
      };

      console.log('Tokenization started!');
      var errorLabel = document.getElementById('global-error');
      errorLabel.innerText = "";
      if (window.PaymeAPI == null || window.PaymeAPI == undefined) {
        window.location.reload()
        return
      }

      const timeout = setTimeout(() => {
        errorLabel.innerText = "העסקה נכשלה. בעקבות חסימה של iOS בדפדפני ספארי, בבקשה לנסות שוב בדפדפן כרום.";
        actions.setSubmitting(false);
        Bugsnag.notify(new Error('Tokenize request timeoutd'), function (event) {
          event.context = `payme tokenize request error hash ${token}, Data => ${JSON.stringify(sale)}`
        });
      }, 30000);

      await window.PaymeAPI.tokenize(sale)
        .then(async (d) => {

          Bugsnag.notify(new Error('Not an error, Tokenize complated'), function (event) {
            event.severity = 'info'
            event.context = `tokenize successfully completed hash ${token}, Data => ${JSON.stringify(sale)}`
          });
          //await recaptchaRef.current.execute().then(async (x) => {
          var res = await DataService.post(API.index.post, {
            name: values.firstName,
            phone: values.phone.length == 8 ? `05${values.phone}` : values.phone,
            token: d.token,
            amount: (amount + fee).toString(),
            hash: token,
            fee: fee.toFixed(2),
            installment: installment,
            captcha: ''
          }).catch((e) => {
            Bugsnag.notify(e, function (event) {
              event.context = `api cc request error ${token}`
            });
          })
          clearTimeout(timeout);
          if (res.data.success) {
            setCurrentStep(5)
            errorLabel.innerText = "";
          }
          else if (res.data.message && res.data.success == false) {
            errorLabel.innerText = res.data.message;
            PaymeAPI(data);
          }
          //});

        })
        .catch(error => {
          clearTimeout(timeout);
          Bugsnag.notify(error, function (event) {
            event.context = `payme tokenize request error hash ${token}, Data => ${JSON.stringify(sale)}`
          });
          if (error) {
            console.error(error);
            if (error.type && error.type === 'tokenize-error') {
              errorLabel.innerText = error?.payload?.status_error_details;
            } else {
              errorLabel.innerText = error.message;
            }
          }
        });
    }
  });
  const validationSchema2 = yup.object({
    firstName: yup.string().required('חובה להזין שם פרטי'),
    lastName: yup.string().required('חובה להזין שם משפחה'),
    phone: yup.string().required('חובה להזין מספר טלפון'),

  });
  const formik2 = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: 'wiwi@nuvola.co.il',
      phone: '',
    },
    validationSchema: validationSchema2,
    onSubmit: async (values) => {

      if (values.phone.includes("_")) {
        formik1.setFieldError("phone", "טלפון לא חוקי")
        return;
      }
      var errorLabel = document.getElementById('global-error1');
      var amount = parseFloat(formik.values.amount);
      // await recaptchaRef.current.execute().then(async (x) => {

      // });
      var res = await DataService.post(API.index.PostBit, {
        name: `${values.firstName} ${values.lastName}`,
        phone: values.phone.length == 8 ? `05${values.phone}` : values.phone,
        amount: (amount + fee).toString(),
        hash: token,
        fee: fee.toFixed(2),
        captcha: ''
      })
      if (res.data.success) {
        setRedirectURL(`<iframe src='${res.data.data}' style='border:none;' height='270' title='Bit' width='100%'></iframe>`)
        setCurrentStep(5)
        // var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        // if (isSafari) {
        //   setTimeout(() => {
        //     window.open(res.data.data, '_top');
        //   })
        // } else {
        //   window.open(res.data.data, '_blank');
        // }
      }
      else if (res.data.message && res.data.success == false) {
        errorLabel.innerText = res.data.message;
      }
    }
  });
  function OpenLink(link) {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      setTimeout(() => {
        window.open(link, '_top');
      })
    } else {
      window.open(link, '_self');
    }
  }

  useEffect(() => {
    async function GetData() {
      var res = await DataService.get(
        API.index.getByToken + "?token=" + token
      );
      if (res.data.success) {
        setLoading(false);
        PaymeAPI(res.data.data);
        setData(res.data.data);
        //formik1.setFieldValue('phone', res.data.data.phone.substring(4))
        //formik2.setFieldValue('phone', res.data.data.phone.substring(4))
      } else {
        window.location.replace("https://www.wiwi.co.il");
      }

    }
    GetData();
  }, [token]);

  function handleNextStep() {
    //Bugsnag.notify(new Error('Test error'))
    if (currentStep == 1) {
      formik.submitForm();
      return;
    }
    if (currentStep == 3) {
      formik1.submitForm();
      return
    } if (currentStep == 4) {
      formik2.submitForm();
      return;
    }
    setExited(false);
    setCurrentStep(currentStep + 1);
  }
  if (show404) {
    return <NotFound />;
  }
  function CalculateAmountWithFee(i) {
    setInstallment(i);
    var index = fees.findIndex(x => x.numberOfPayments == i);
    if (index !== -1) {
      if (fees[index].percentage == false) {
        setFee(round(fees[index].fees, 1))
      }
      else {
        setFee(round((parseFloat(formik.values.amount) * fees[index].fees) / 100, 1))
      }
    }
  }
  if (loading) {
    return (
      <div>
        <Dialog PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }} style={{ color: "#fff", zIndex: 99999, }} open={true}>
          <img height="150" src='/images/loader.gif' />
        </Dialog>
      </div>)
  }
  function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
  const marks = fees.map(x => ({ value: x.numberOfPayments, label: x.numberOfPayments }));
  const maxInstallment = Math.max.apply(Math, fees.map(function (o) { return o.numberOfPayments; }))
  return (
    <div>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LfjLckfAAAAAKpyvN9Q_7T7thIYRvrreuFE2dwd"
        onChange={(v) => { }}
        size="invisible"
      />
      <Grid container className={classes.container} justifyContent="center" alignContent="center">
        {/* <Grid item xs={12} md={3} lg={4}></Grid> */}
        <Grid item xs={12} style={{ maxWidth: '650px' }}>
          <Card variant="outlined" className={classes.card}>
            <CardContent>
              <br />
              <div className={classes.logoContainer}>
                <img width="100%" src="/images/wiwi-logo.svg" />
              </div>
              <br />
              <Typography variant="h5" gutterBottom color="secondary" align="center">{data?.event}</Typography>

              <Slide direction="right" onExited={() => setExited(true)} timeout={animation} in={currentStep == 0 && exited == true} mountOnEnter unmountOnExit>
                <div style={{ minHeight: '150px' }}>
                  <Typography variant="h4" className={classes.title} color="primary" align="center">סימן שכולם מפרגנים</Typography>
                  <Typography variant="h6" color="textSecondary" align="center">נגמר עידן הצ'קים והמזומן, ברוכים הבאים לעידן החדש של WiWi
                    מתנות באשראי מבלי לבזבז את הזמן בצ'קים ומזומן</Typography>
                </div>
              </Slide>
              <Slide direction="right" onExited={() => setExited(true)} timeout={animation} in={currentStep == 1 && exited == true} mountOnEnter unmountOnExit>
                <div style={{ minHeight: '150px' }}>
                  <br />
                  <form autoComplete="off" onSubmit={formik.handleSubmit}>
                    <TextField
                      autoFocus
                      fullWidth
                      name="amount"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      inputProps={{ style: { fontSize: '26px' }, inputMode: 'numeric' }}
                      InputLabelProps={{
                        style: { fontSize: '26px' }
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">₪</InputAdornment>
                      }}
                      error={formik.touched.amount && Boolean(formik.errors.amount)}
                      helperText={formik.touched.amount && formik.errors.amount}
                      label="כמות">
                    </TextField>
                  </form>
                </div>

              </Slide>
              <Slide direction="right" onExited={() => setExited(true)} timeout={animation} in={currentStep == 2 && exited == true} mountOnEnter unmountOnExit>
                <div style={{ minHeight: '150px' }}>
                  <Typography variant="h6" color="textSecondary" align="center">הוצאה כבדה מדי? ניתן לשלם בתשלומים</Typography>
                  <br />
                  <Slider
                    defaultValue={1}
                    value={installment}
                    aria-labelledby="discrete-slider-always"
                    step={null}
                    max={maxInstallment}
                    min={1}
                    marks={marks}
                    valueLabelDisplay="on"
                    onChange={(e, v) => CalculateAmountWithFee(v)}
                  />
                  <br />
                  <Typography variant="h5" align="center">סה"כ לתשלום:&nbsp;<span className="notranslate" style={{ fontWeight: 'bolder' }}>{(parseFloat(formik.values.amount) + fee).toFixed(2)}</span>&nbsp;₪</Typography>
                  <Typography variant="h6" align="center">סכום המתנה: &nbsp;<span className="notranslate" style={{ fontWeight: 'bolder' }}>{(parseFloat(formik.values.amount)).toFixed(2)}</span>&nbsp;₪</Typography>
                  {/* <Typography variant="h6" align="center">עמלה: &nbsp;<span style={{ fontWeight: 'bolder' }}>{(fee).toFixed(2)}</span>&nbsp;₪</Typography> */}
                  <Typography variant="h6" color="textSecondary" align="center">שימו ♥ גם אם תבחרו לחלק לתשלומים, החוגגים יקבלו את הסכום המלא מיד!</Typography>
                </div>

              </Slide>
              <Slide direction="right" onExited={() => setExited(true)} timeout={animation} in={currentStep == 3 && exited == true}>
                <div style={{ minHeight: '190px', display: (currentStep == 3 && exited == true) ? 'block' : 'none' }}>
                  <form onSubmit={formik1.handleSubmit}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="שם פרטי"
                          name="firstName"
                          value={formik1.values.firstName}
                          onChange={formik1.handleChange}
                          error={formik1.touched.firstName && Boolean(formik1.errors.firstName)}
                          helperText={formik1.touched.firstName && formik1.errors.firstName} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="שם משפחה"
                          name="lastName"
                          value={formik1.values.lastName}
                          onChange={formik1.handleChange}
                          error={formik1.touched.lastName && Boolean(formik1.errors.lastName)}
                          helperText={formik1.touched.lastName && formik1.errors.lastName} />
                      </Grid>
                      <Grid item xs={6}>
                        <InputMask fullWidth
                          label="מספר טלפון"
                          name="phone"
                          mask="0599999999"
                          value={formik1.values.phone}
                          onChange={formik1.handleChange}
                          error={formik1.touched.phone && Boolean(formik1.errors.phone)}
                          helperText={formik1.touched.phone && formik1.errors.phone}
                        >
                          {(inputProps) => (
                            <TextField  {...inputProps} />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth label="תעודת זהות"
                          name="socialId"
                          value={formik1.values.socialId}
                          onChange={formik1.handleChange}
                          error={formik1.touched.socialId && Boolean(formik1.errors.socialId)}
                          helperText={formik1.touched.socialId && formik1.errors.socialId}
                        />
                      </Grid>
                    </Grid>
                  </form>
                  <Grid container spacing={1}>
                    <Grid item sm={6} xs={12}>
                      <label className={classes.inputLabel}>מספר כרטיס אשראי</label>
                      <div className={classes.inputWrapper} id='card-number-container-he'></div>
                      <label id="card-number-error" className={classes.inputErrorLabel}></label>
                    </Grid>
                    <Grid item sm={2} xs={6}>
                      <label className={classes.inputLabel}>תאריך תוקף</label>
                      <div className={classes.inputWrapper} id='card-expiration-container-he'></div>
                      <label id="card-expiration-error" className={classes.inputErrorLabel}></label>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                      <label className={classes.inputLabel}>3 ספרות בגב הכרטיס</label>
                      <div className={classes.inputWrapper} id='card-cvv-container-he'></div>
                      <label id="card-cvv-error" className={classes.inputErrorLabel}></label>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <label id="global-error" className={classes.globalErrorLabel}></label>
                    </Grid>
                  </Grid>
                </div>
              </Slide>
              <Slide direction="right" onExited={() => setExited(true)} timeout={animation} in={currentStep == 4 && exited == true}>
                <div style={{ minHeight: '190px', display: (currentStep == 4 && exited == true) ? 'block' : 'none' }}>
                  <Typography variant="h5" align="center">סה"כ לתשלום:&nbsp;<span className="notranslate" style={{ fontWeight: 'bolder' }}>{(parseFloat(formik.values.amount) + fee).toFixed(2)}</span>&nbsp;₪</Typography>
                  <Typography variant="h6" align="center">סכום המתנה: &nbsp;<span className="notranslate" style={{ fontWeight: 'bolder' }}>{(parseFloat(formik.values.amount)).toFixed(2)}</span>&nbsp;₪</Typography>
                  <form onSubmit={formik2.handleSubmit}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="שם פרטי"
                          name="firstName"
                          value={formik2.values.firstName}
                          onChange={formik2.handleChange}
                          error={formik2.touched.firstName && Boolean(formik2.errors.firstName)}
                          helperText={formik2.touched.firstName && formik2.errors.firstName} />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="שם משפחה"
                          name="lastName"
                          value={formik2.values.lastName}
                          onChange={formik2.handleChange}
                          error={formik2.touched.lastName && Boolean(formik2.errors.lastName)}
                          helperText={formik2.touched.lastName && formik2.errors.lastName} />
                      </Grid>
                      <Grid item xs={12}>
                        <InputMask fullWidth label="מספר טלפון"
                          name="phone"
                          mask="0599999999"
                          value={formik2.values.phone}
                          onChange={formik2.handleChange}
                          error={formik2.touched.phone && Boolean(formik2.errors.phone)}
                          helperText={formik2.touched.phone && formik2.errors.phone}
                        >
                          {(inputProps) => (
                            <TextField  {...inputProps} />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <label id="global-error1" className={classes.globalErrorLabel}></label>
                      </Grid>
                    </Grid>
                  </form>

                </div>
              </Slide>
              <Slide direction="right" onExited={() => setExited(true)} timeout={animation} in={currentStep == 5 && exited == true} mountOnEnter unmountOnExit>
                <div style={{ textAlign: 'center' }}>
                  {formik.values.method === "Bit" &&
                    <div dangerouslySetInnerHTML={{ __html: redirectURL }}></div>
                  }
                  {formik.values.method !== "Bit" &&
                    <div>
                      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '70px', marginBottom: '70px' }}>
                        <div className={classes.heartShape}>
                        </div>
                        <br />
                      </div>
                      <Typography variant="h5" gutterBottom color="secondary" align="center">תודה רבה!</Typography>
                      <Typography variant="h5" gutterBottom color="primary" align="center">{formik1.values.firstName}</Typography>
                      <Button variant="contained" color="primary" style={{ backgroundColor: '#46d946' }} onClick={() => OpenLink(`/b/${data?.eventHash}`)}>כתיבת ברכה</Button>
                    </div>
                  }
                </div>
              </Slide>
            </CardContent>
            <CardActions style={{ justifyContent: 'center', flexDirection: 'column', display: currentStep == 5 && 'none' }}>
              {currentStep != 1 &&
                <LoadingButton disabled={formik.isSubmitting} loading={(formik.isSubmitting && formik.values.method == "Credit Card") || formik1.isSubmitting || formik2.isSubmitting} onClick={() => handleNextStep()}
                  classes={{ root: classNames(classes.gradientBtn, classes.margin) }} size='large'>המשך</LoadingButton>}
              {currentStep == 1 &&
                <Grid container spacing={2} style={{ marginTop: '-50px', marginBottom: 20, width: '100%' }}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <LoadingButton fullWidth disabled={formik.isSubmitting} loading={(formik.isSubmitting && formik.values.method == "Credit Card") || formik1.isSubmitting || formik2.isSubmitting}
                      onClick={() => {
                        formik.setFieldValue("method", "Credit Card");
                        formik.submitForm();
                      }}
                      classes={{ root: classNames(classes.gradientBtn, classes.margin) }} size='large'>תשלום בכרטיס אשראי</LoadingButton>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <LoadingButton fullWidth disabled={formik.isSubmitting} loading={(formik.isSubmitting && formik.values.method == "Bit") || formik1.isSubmitting}
                      classes={{ root: classNames(classes.gradientBtn, classes.margin) }}
                      onClick={() => {
                        formik.setFieldValue("method", "Bit");
                        formik.submitForm();
                      }}>תשלום בביט</LoadingButton >
                  </Grid>

                </Grid>

              }
              <div style={{ display: currentStep == 0 && 'none' }}>
                <Button onClick={() => {
                  setExited(false)
                  if (currentStep == 4) {
                    setCurrentStep(1);
                  } else {
                    setCurrentStep(currentStep - 1);
                  }
                }} className={classes.backButton} >חזור</Button>
              </div>

            </CardActions>

          </Card>
          <Decoration
            mode={'light'}
            gradient={true}
            decoration={true}
            bgPosition={'half'}
            horizontalMenu={false}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Pay);
