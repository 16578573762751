import { darken } from '@material-ui/core/styles/colorManipulator';

export const gradientBgLight = (theme) => `linear-gradient(-45deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 33%, ${theme.palette.primary.main} 100%);`;
export const gradientBgDark = (theme) => `linear-gradient(-45deg, ${darken(theme.palette.primary.main, 0.4)} 0%, ${darken(theme.palette.primary.main, 0.4)} 33%, ${darken(theme.palette.secondary.main, 0.4)} 100%);`;


const styles = theme => ({
  bgWrap: {
    background: theme.palette.background.default,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
      height: '100%',
      bottom: 0,
      left: 0,
      zIndex: -1,
    },

  },
  headerBg: {},
  halfBg: {},
  fullBg: {},
  bgbar: {
    display: 'flex',
    // alignItems:'flex-start',
    //  backgroundAttachment: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
    },
    //  position: 'absolute',
    '&$halfBg': {
      height: 310,
      [theme.breakpoints.down('md')]: {
        height: 200,
        left: -4
      }
    },
  },
  gradientBg: {
    backgroundImage: theme.palette.type === 'dark' ? gradientBgDark(theme) : gradientBgLight(theme),
    //  backgroundAttachment: 'fixed'
  },
  decorationWave: {
    transform: 'rotate(180deg)',

    width: '100%',
    //  height: 'auto',
    position: 'absolute',
    //  left: 0,
    // top: -4,
    marginTop: '-25px',
    // [theme.breakpoints.down('sm')]: {
    //   marginTop: '-30px',
    // },
    //  bottom: -5,
    //  transform: 'scale(1.1,0.8)',
    //  transformOrigin: 'bottom',
  },
  decorationPetal: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  sidebarLayout: {},
  topbarLayout: {},

  footer: {
    alignContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: '3px'
    }
    //  backgroundImage: theme.palette.type === 'dark' ? gradientBgDark(theme) : gradientBgLight(theme),
  },
  rightFooter: {
    paddingLeft: '10px',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: "4px !important"
    }
  },
  centerFooter: {
    textAlign: 'center'
  },
  leftFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      padding: "4px !important"
    }
  },
  labelColor: {
    color: 'white',
    [theme.breakpoints.down('md')]: {
      //   color:'gray'
    }
  },
  mobileOnly: {
    //paddingLeft: '25px',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  desktopOnly: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
});

export default styles;
