//const DEV_ENDPOINT = "https://localhost:5001/api/";
const DEV_ENDPOINT = "https://api.wiwi.co.il/api/";
const PROD_ENDPOINT = "https://api.wiwi.co.il/api/";
const BitFee = 0.5;
const FEDTax = 1.17;
//const PROD_ENDPOINT = "https://testing11.azurewebsites.net/api/";
function GetURL(){
  return (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')? DEV_ENDPOINT : PROD_ENDPOINT
}

export default GetURL;
export {BitFee};
export {FEDTax};